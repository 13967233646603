/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/roboto/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/roboto/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/roboto/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/roboto/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/roboto/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/roboto/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */ url('../fonts/roboto/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/roboto/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    color: rgb(60, 78, 90);
    font-family: Roboto, sans-serif;
}

.hyphens {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

p a, p a:hover, p a:visited {
    color: #3C4E5A;
}

nav a {
    cursor: pointer;
}

#start {
    width: 100%;
    height: 100vh;
}

.claim {
    bottom: 8em;
    position: absolute;
    width: 40%;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    right: 11em;
}

.claim .image {
    max-width: 100%;
}

.home-titel {
    padding: 15px;
}

/*fb feed*/
.fb-feed a {
    color: #3C4E5A;
}

/*.cff-top {*/
/*padding: 15px !important;*/
/*}*/

/*.fb-feed ._2p3a {*/
/*width: 100% !important;*/
/*min-width: 100% !important;*/
/*margin-left: auto !important;*/
/*margin-right: auto !important;*/
/*}*/
.cff-likebox {
    margin: -16px 0 30px 0 !important;
    /*text-align: center;*/
}

/*.cff-likebox .fb_iframe_widget {*/
/*float:none; }*/

/*header*/
.header {
    padding: 0 !important;
    margin-bottom: 2em;
}

header .header-image {
    width: 100%;
    height: 430px;
}

header h1 {
    display: none;
}

div.campaign {
    position: absolute;
    bottom: 15px;
    margin-left: 1px;
    width: 84px;
    height: 84px;
}

span.down {
    position: absolute;
    bottom: 15px;
    margin-left: 1px;
    width: 65px;
    height: 65px;
    border: 0px solid;
    background-image: url(../img/mobile-pfeil.png);
    padding-top: 0px;
    z-index: 10;
}

span.camp {
    position: absolute;
    z-index: 100;
    background-image: url(../img/campn.png);
    background-repeat: no-repeat;
    height: 30px;
    width: 0px;
    margin-left: 80px;
    background-position: center left;
    margin-top: 11px;
}

header .titleholder {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 370px;
    padding-right: 0;
    z-index: 10;
    color: #fff;
    text-align: right;
    letter-spacing: 1px;
}

.titleholder h2 {
    line-height: 28px;
    width: auto;
    font-size: 30px;
    font-weight: normal;
    padding: 1px 13px 0px 13px;
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    letter-spacing: 1px;
    display: inline;
    /*background-color: rgba(118, 158, 179);*/
    background-image: url(../img/bg-section-h2neu.png);
    background-repeat: repeat-x;
}

.titleholder span {
    font-size: 15px;
    padding-right: 13px;
    margin-top: 4px;
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    display: block;
    font-weight: normal;
}

header .titlebackground {
    /*background-color: rgba(118, 158, 179);*/
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: 390px;
    background-image: url(../img/bg-section-h2neu.png);
    background-repeat: repeat;
}

/*content*/

.blue {
    background-color: #C9D8E1;
    padding: 15px;
    width: 100%;
}

.header-no-img {
    margin: 12px;
    margin-left: 0;
    padding: 8px 12px 10px 0;
    width: 60%;
}

.article-header {
    position: absolute;
    height: 0;
    background: rgba(255, 255, 255, 0.7);
    min-height: 100px;
    height: auto;
    z-index: 10;
    margin: 12px;
    padding: 8px 12px 10px;
    width: 60%;
}

.article-left {
    padding-left: 0;
}

.article-right {
    padding-right: 0;
}

.collection-left {
    padding-left: 0;
}

.collection-right {
    padding-right: 0;
}

/*.article-image-wrapper {*/
/*margin-bottom: 25px;*/
/*}*/
.article-title, .article-title a, .article-title a:hover, .article-title a:visited, .article-title a:active {
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    font-size: 26px !important;
    font-weight: bold;
    line-height: 32px;
    margin: 0;
    color: #000;
    text-decoration: none;
}

.media-wrapper {
    margin: 0 0 15px;
    width: 100%;
}

.media-item {
    border: solid 5px white;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.video-wrapper {
    margin: 0 0 15px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-item {
    border: solid 5px white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

figcaption {
    font-size: 14px;
    font-style: italic;
    background: white;
    margin-top: -5px;
    padding: 1px 12px 4px;
}

.article-text {
    font: 15px/21px Roboto, sans-serif;
    color: #3C4E5A;
}

.article-text ul {
    margin-top: -5px;
    padding-left: 45px;
}

.article-text li {
    padding-bottom: 10px;
    padding-right: 100px;
}

/*newspage and newsitem*/

.newspage-item {
    margin: 30px 0;
}

.newspage-item:first-child {
    margin-top: 15px;
}

.article-subtitle {
    font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    font-size: 17px;
    margin: 10px 0 10px 0;
    line-height: 20px;
    font-weight: bold;
}

.news-subtitle {
    color: #3C4E5A;
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    font-size: 17px;
    line-height: 22px;
    /*padding-right: 10px;*/
}

.news-text {
    width: 90%;
    font: 15px/21px Roboto, sans-serif;
}

/*picturecollection*/
.box_download {
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 15px;
    padding-top: 35px;
}

.box_download:last-child {
    border-bottom: none;
}

/*linkcollection*/
.news-wrapper {
    margin: 10px;
}

.news-list-item_medien {
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    padding-bottom: 25px;
}

.news-list-date {
    display: block;
    padding-top: 0px;
    padding-bottom: 7px;
}

.news-list-browse, .news-list-date {
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.quelle {
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    font-size: 15px;
    padding-top: 5px;
    text-decoration: none;
}

.zeitungstitel {
    font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    font-size: 15px;
    padding-top: 5px;
    text-decoration: none;
}

.quelllink a {
    font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    font-size: 15px;
    text-decoration: none;
    padding-top: 10px;
    color: rgb(60, 78, 90);
}

.quelllink a i {
    padding-right: 5px;
    color: #769eb3;
    vertical-align: middle;
}

.browseLinksWrap a, .showResultsWrap {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-bottom: 5px;
    color: rgb(60, 78, 90);
}

/*search*/
.search-title {
    margin-bottom: 0;
}

.search-text {
    margin-top: 5px;
    margin-bottom: 10px;
}

.search-title a, .search-title a:visited, .search-title a:hover, .search-title a:active {
    font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    color: #3C4E5A;
    text-decoration: underline;
    font-size: 15px;
    margin-bottom: 0;
}

/*sidebar*/

aside .aside-row {
    background: #769eb3;
}

aside a, aside a:hover, aside a:visited {
    text-decoration: none;
    color: #000;
}

aside .aside-wrapper {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
}

aside .sidebar-container {
    border: solid 1px white;
    padding: 8px 15px;
    margin: 17px 0;
}

aside h2 {
    margin-top: 0;
    margin-bottom: 15px;
}

aside h2 a {
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    font-size: 26px;
    background-color: #fff;
    display: block;
    padding: 5px 10px;
}

aside .sidebar-inner {
    margin: 5px 0px;
}

aside .news-container .news-item .news-date a {
    background-color: #c9d8e1;
    margin: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 23px;
    padding-left: 5px;
    display: block;
    height: 20px;
}

aside .news-container .news-item {
    background-color: #fff;
    width: 100%;
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
}

aside .news-container .news-item h4 {
    margin: 9px 0 0;
    padding: 0 20px 8px 8px;
    line-height: 14px;
    font-size: 12px;
}

aside .news-container .news-item h4 a {
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
}

aside .featured-container .featured-collection .featured-item .featured-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
}

aside .featured-container .featured-collection .featured-item .featured-text {
    background-color: #fff;
    width: 100%;
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
}

aside .featured-container .featured-collection .featured-item .featured-text h4 {
    margin: 5px 0 0;
    padding: 8px 20px 8px 8px;
    line-height: 14px;
    font-size: 12px;
}

aside .featured-container .featured-collection .featured-item .featured-text h4 a {
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
}

/*aside  div.morelink {*/
/*text-align: center;*/
/*}*/
div.morelink a {
    /*background-image: url(../img/pfeilkreis.png);*/
    /*background-position: left top;*/
    /*background-repeat: no-repeat;*/
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    font-size: 14px;
    padding-top: 0;
    color: #000;
    text-decoration: none;
    vertical-align: bottom;
}

div.morelink a i {
    color: white;
    padding-right: 5px;
}

/*suche*/

.footerservice {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 2em;
}

.footerservice a {
    background-color: #769eb3 !important;
}

.footerservice fieldset, .searchdiv fieldset {
    border: none;
}

.footerservice fieldset i, .searchdiv fieldset i {
    osition: relative;
    color: white;
    vertical-align: sub;
}

fieldset button {
    cursor: pointer;
    border: none;
    border: none;
    color: unset;
    background: unset;
}

div.searchdiv.show {
    position: absolute;
    top: 35px;
    margin-left: -80px;
    background-image: url(../img/suchfeld.png);
    background-repeat: no-repeat;
    display: none;
    z-index: 50;
    height: 38px;
    padding-top: 9px;
}

.searchdiv fieldset {
    padding-left: 6px;
}

.searchdiv input {
    border: none;
}

nav div.service a.facebook {
    float: left;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 30px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    background-image: url(../img/facebook_off.png);
    background-repeat: no-repeat;
}

nav div.service a.instagram {
    float: left;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 30px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    background-image: url(../img/instagram_off.png);
    background-repeat: no-repeat;
}

nav div.service a.searchbtn {
    float: left;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 30px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    background-image: url(../img/lupe_off.png);
    background-repeat: no-repeat;
}

nav div.service a.lg {
    float: right;
    width: 104px;
    height: 40px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(../img/csu-logo.png);
    margin-top: 3px;
}

.service {
    display: flex;
    justify-content: flex-end;
    float: right;
    border: 0px solid;
    display: block;
}

.service a {
    padding: 5px;
}

.newsletter-form {
    margin: 0 0 4rem;
}

.newsletter-form .form-group {
    margin-bottom: 1.5rem;
}

.newsletter-form .form-group.submit {
    text-align: center;
    margin: 2.5rem;
}

.newsletter-form .form-group.submit .button {
    width: 50%;
    padding: 1rem;
    border: none;
    background: white;
    font-size: 1.2em;
    text-transform: uppercase;
    font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    /*border: 3px solid white;*/
    transition: box-shadow 0.2s;
}

.newsletter-form .form-group.submit .button:hover,
.newsletter-form .form-group.submit .button:active,
.newsletter-form .form-group.submit .button:focus {
    /*border: 3px solid #769eb4;*/
    box-shadow: 2px 2px 6px 3px #769eb4;
}

.newsletter-form input {
    width: 100%;
    width: 100%;
    height: 2.5rem;
    margin-top: 0.5rem;
    font-size: 1em;
    color: #3c4e5a;
    padding: 5px;
}

.newsletter-form label {
    width: 100%;
}

.newsletter-form .radio-group {
    display: flex;
    justify-content: flex-start;
}

.newsletter-form .radio-group .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 1rem;
}

.newsletter-form .radio-group input {
    width: auto;
    margin-right: 0.5rem;
}


/*--------------------------------------------*/

/*-----------------------------------*/

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}

@media (min-width: 993px) {
    .footerservice {
        justify-content: flex-end;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {

    aside {
        margin-top: 2em;
    }

    aside .aside-wrapper {
        width: 95%;
    }

    .featured-container {
        display: none;
    }

    .footerservice {
        /*display: none;*/
        justify-content: center;
        align-items: center;
    }

    .footerservice .lg {
        padding-left: 15px;
    }

    .article-left {
        padding-right: 0;
    }

    .article-right {
        padding-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    nav {
        max-width: 100% !important;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media screen and (min-width: 768px) {
    .mobilemenu {
        display: none;
    }

    .main-menu i {
        display: none;
    }

    nav i {
        /*display: none;*/
    }

    span.down {
        float: left;
        bottom: 15px;
        margin-left: 1px;
        width: 86px;
        height: 86px;
        border: 0px solid;
        cursor: pointer;
        background-image: url(../img/pfeil-down.png);
        background-position: center center;
        background-repeat: no-repeat;
    }

    /*nav*/
    nav {
        width: 100%;
        position: absolute;
        z-index: 200;
        margin-top: 45px;
        margin-left: 0px;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 !important;
    }

    nav a {
        display: block;
        margin-bottom: 0px;
        padding: 0px 17px 0px 18px;
        width: auto;
        text-align: left;
        text-decoration: none;
        font-weight: bold;
        font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
        font-size: 16px;
        line-height: 30px;
        color: #000000;
        background: #ffffff;
        float: left;
    }

    .menu-img {
        padding-left: 14px;
    }

    .navwrapper {
        width: 100%;
    }

    .navhead {
        width: 65%;
        margin-left: 15px;
    }

    .navhead h2 {
        font-family: 'Glypha LT W01 65 bold', Roboto, sans-serif;
        font-weight: normal;
        font-size: 34px;
        color: #000;
        text-transform: uppercase;
        line-height: 65px;
        margin-bottom: 0px;
        margin-top: 30px;
    }

    .navhead h3 {
        font-family: 'Glypha LT W01 45 Light', Roboto, sans-serif;
        float: left;
        clear: both;
        font-size: 18px;
        color: #000;
        margin-top: 10px;
        line-height: 22px;
    }

    nav a:hover, nav ul li:hover .main-menu, nav a:active, nav a .active {
        text-decoration: none;
        background-color: #779DB3;
    }

    nav ul {
        margin: 0;
        padding: 0;
        background: #779db3;
        list-style: none;
    }

    nav .second {
        position: absolute;
        margin-top: 30px;
        display: none;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    nav .second li {
        display: inline-block;
        margin-bottom: 30px;
    }

    nav .second li a {
        background-color: #486276;
        padding-right: 0;
    }

    nav .second .menu-item-title {
        line-height: 22px;
        font-size: 18px;
        color: white;
        padding-left: 15px;
    }

    nav .second .desc {
        font-size: 13px;
        color: #c9d8e1;
        line-height: 18px;
        padding-left: 15px;
        width: 90%;
    }

    .menu-item {
        /*width: 31%;*/
        /*margin: 1%;*/
        margin: -2px;
    }

    .menu-item-wrapper {
        background-color: #486276;
        display: flex;
        height: 140px;
        justify-content: space-between;
    }

    .menu-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 0px;
    }

    nav .first li:hover .second {
        display: block;
    }

}

@media screen and (max-width: 767px) {

    .container {
        max-width: 100% !important;
    }

    .overflow {
        overflow: hidden;
    }

    nav .service {
        display: none;
    }

    aside {
        margin-top: 30px;
    }

    aside .aside-wrapper {
        width: 94%;
    }

    footer {
        margin-top: 2em;
    }

    footer .footer-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    /*mobile menu*/
    .mobilemenu {
        background-color: #769eb3;
        box-shadow: 0 0 5px rgba(0, 0, 0, .4);
        position: fixed;
        top: 0;
        left: 0;
        padding: 30px;
        height: 75px;
        color: white;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
    }

    .mobilemenu a {
        font-size: 40px;
        font-family: 'Glypha LT W01 55 Roman', Roboto, sans-serif;
    }

    .mobilemenu i {
        font-size: 30px;
    }

    nav {
        height: 100%;
        width: 0 !important;
        padding: 0 !important;
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
        background-color: #769eb3;
        overflow: visible;
        transition: 0.5s;
        padding-top: 60px;
        -webkit-transition: width 0.5s;
        -moz-transition: width 0.5s;
        -ms-transition: width 0.5s;
        -o-transition: width 0.5s;
        transition: width 0.5s;
    }

    nav a {
        /*padding: 8px 8px 8px 32px;*/
        text-decoration: none;
        font-size: 25px;
        color: white;
        display: block;
        transition: 0.3s;
    }

    nav a:hover {
        color: #C9D8E1;
    }

    nav #navopener {
        cursor: pointer;
        right: 25px;
    }

    nav .menu-img, nav .desc, nav .navwrapper {
        display: none;
    }

    nav.open {
        width: 50vw !important
    }

    /*nav .first {*/
    /*margin-top: 40%;*/
    /*}*/
    nav ul {
        list-style: none;
        margin-bottom: 10%;
        padding: 30px;
        transition: opacity 1.25s cubic-bezier(0.35, 0.03, 0.65, 0.99);
        opacity: 0;
        height: 100vh;
        padding-top: 91px;
        margin-top: 0;
        padding-bottom: 10px;
    }

    nav.open ul {
        opacity: 1;
    }

    nav.open .first {
        overflow-y: scroll;
    }

    .main-menu {
        text-align: right;
        margin-top: 30px;
        width: 36vw;
    }

    .main-menu.nosub {
        padding-right: 25px;
    }

    .main-menu i {
        font-size: 15px;
        padding: 7px;
        vertical-align: text-bottom;
    }

    /*nav li a, nav li a:visited {*/
    /*font-size: 25px;*/
    /*color: white;*/
    /*text-decoration: none;*/
    /*margin: 5px;*/
    /*}*/
    #main-menu .second {
        padding: 15px 10px 0px 0;
        display: none;
        height: auto;
        /*transition: all 0.5ms;*/
    }

    li.menu-item a {
        font-size: 16px;
        padding: 5px;
    }

    /*#main-menu > ul > li > a:hover {*/
    /*color: #C9D8E1;*/
    /*}*/
    .collection-left {
        padding-right: 0;
    }

    .collection-right {
        padding-left: 0;
        text-align: center;
    }

    .newspage {
        padding-left: 15px;
    }

    .article-title {
        font-size: 20px !important;
        line-height: 24px;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {

}

/*Small devices (landscape phones, 576px and up)*/

@media screen and (min-width: 577px) {
    /*footer*/
    footer {
        background: #769eb3;
        padding-bottom: 45px;
        margin-top: 4em;
    }

    footer ul {
        list-style: none;
        float: left;
        display: block;
        width: 100%;
        padding: 0px;
        border: 0px solid #fff;
        margin-top: 40px;
        background-image: url(../img/bg-footer-ul.png);
        background-repeat: repeat-x;

    }

    footer ul li {
        float: left;
        margin: 0px;
        padding: 0px 3px;
        list-style: none;
        border: 0px solid;
        overflow: hidden;
        width: auto;
    }

    footer ul li a {
        color: #000;
        float: left;
        width: auto;
        padding: 0px 4px 0px 15px;
        margin-bottom: 0;
        /* font-size: 16px; */
        font-size: 14px;
        line-height: 30px;
        font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
        text-decoration: none;
        margin-right: 0px;
        font-weight: normal;
    }

    footer ul li ul {
        float: left;
        clear: both;
        width: 115px;
        margin: 0px;
        padding: 0px;
        border: 0px solid;
        background-color: #779eb3;
        padding-top: 10px;
        padding-left: 10px;
        margin-top: 0px;
        background-image: none;
    }

    footer ul li ul li {
        width: 110px;
        margin: 0px;
        padding: 0px;
        float: left;
        clear: both;
        overflow: visible;
        padding-bottom: 5px;
    }

    footer ul li ul li a {
        background-color: transparent;
        color: #3c4e5a;
        font-size: 13px;
        font-family: 'Roboto', sans-serif;
        line-height: 12px;
        padding-left: 5px;
    }
}

@media screen and (max-width: 576px) {
    .claim {
        bottom: 7em;
        width: 60%;
        right: 2em;
    }

    header .header-image {
        height: 200px;
        margin-top: 75px;
    }

    .titleholder, .titlebackground {
        display: none;
    }

    /*.mobilemenu {*/
    /*padding: 25px;*/
    /*height: 55px;*/
    /*}*/
    .mobilemenu a {
        font-size: 30px;
    }

    nav.open {
        width: 100% !important;
    }

    nav ul {
        margin-bottom: 0;
        padding: 0 30px;
    }

    nav ul.first {
        padding-top: 100px;
    }

    .main-menu {
        margin-top: 0;
        width: 85vw;
    }

    #main-menu .second {
        padding: 5px 5px 10px 0;
    }

    .main-menu.nosub {
        padding-right: 16px;
    }

    .main-menu i {
        font-size: 12px;
        padding: 3px;
    }

    nav a {
        padding: 0px 8px 0px;
        font-size: 18px;
        padding: 5px;
    }

    li.menu-item a {
        font-size: 13px;
    }

    footer {
        background-color: #769eb3;
    }

    footer ul {
        list-style: none;
        margin-top: 3em;
    }

    footer ul li {
        margin: 15px 0;
    }

    footer a, footer a:visited {
        font-size: 15px;
        color: #3C4E5A;
        text-decoration: none;
        background-color: white;
        padding: 4px;
        margin: 5px;
    }

    footer ul li a {
        font-family: 'Glypha LT W01 65 Bold', Roboto, sans-serif;
    }

    footer ul li ul li a {
        font: 15px/21px Roboto, sans-serif;
    }

    footer a:hover {
        text-decoration: underline;
    }

    footer ul ul {
        margin: 7px;
    }

    footer ul ul li {
        margin: 0;
    }

    footer ul ul a {
        background-color: #769eb3 !important;
    }

    .footerservice fieldset, .searchdiv fieldset {
        margin-bottom: 0;
    }
}

.facebook-widget {
    border: none;
    left: 0;
    width: 100%;
    height: 100%;
}

.facebook-item {
    border: none;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 90vh;
}

.vbcn-settings-button {
    border: none;
    background: none;
    display: block;
    width: 100%;
    text-align: right;
    cursor: pointer;
    font-size: 15px;
    color: #3C4E5A;
    text-decoration: none;
    padding: 4px;
    margin: 5px;
}

.vbcn-settings-button:focus {
    outline: none;
}

@media screen and (max-width: 576px) {
    .vbcn-settings-button {
        text-align: center;
    }
}
